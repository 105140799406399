.App {
  font-size: 18px;
  text-align: center;
  width: 100%;
  /* min-height: 50vh; */
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border: 1px solid transparent;
}

.App > * {
  margin: 1rem 0;
}

.App-controls {
  display: flex;
  justify-content: space-between;
}

.App-controls > * {
  margin: 0 1rem;
}

.App-dropzone {
  background-color: lightblue;
  padding: 4rem 6rem;
  border-radius: 10px;
}

.App-input-container {
  display: flex;
  align-items: center;
}

.App-input-container > input {
  font-size: 16px;
  margin-left: 1rem;
}

.App-results {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 2rem;
}

.App-result table {
  width: 100%;
}

.App-result thead {
  position: relative;
}

.App-result thead::after {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  display: block;
  content: '';
  height: 2px;
  background-color: black;
}

.App-result tbody tr {
  position: relative;
}

.App-result tbody tr::after {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  display: block;
  content: '';
  height: 1px;
  background-color: lightgray;
}

.App-result td,
.App-result,
th {
  padding: 1rem;
}
